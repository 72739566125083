/**
 * Copyright 2024 SweetCode. All rights reserved.
 *
 * Load Snapchat Ads functions
 * */

(function (wpm, $, undefined) {

	wpm.snapchatGetUserInfo = () => {

		if (!wpmDataLayer?.pixels?.snapchat?.advanced_matching) {
			return {}
		}

		let userInfo = {}

		if (wpmDataLayer?.user?.email?.sha256) {
			userInfo.user_hashed_email = wpmDataLayer.user.email.sha256
		}

		if (wpmDataLayer?.user?.phone?.sha256?.snapchat) {
			userInfo.user_hashed_phone_number = wpmDataLayer.user.phone.sha256.snapchat
		}

		return userInfo
	}

	wpm.loadSnapchatPixel = () => {

		try {
			wpmDataLayer.pixels.snapchat.loaded = true;

			// @formatter:off
			(function(e,t,n){if(e.snaptr)return;var a=e.snaptr=function()
			{a.handleRequest?a.handleRequest.apply(a,arguments):a.queue.push(arguments)};
				a.queue=[];var s='script';r=t.createElement(s);r.async=!0;
				r.src=n;var u=t.getElementsByTagName(s)[0];
				u.parentNode.insertBefore(r,u);})(window,document,
				'https://sc-static.net/scevent.min.js');

			snaptr("init", wpmDataLayer.pixels.snapchat.pixel_id, wpm.snapchatGetUserInfo());

			snaptr("track", "PAGE_VIEW")
			// @formatter:on


		} catch (e) {
			console.error(e)
		}
	}

	wpm.getSnapchatOrderItemIds = () => {
		let contentIds = []

		Object.values(wpmDataLayer.order.items).forEach(item => {

			if (wpmDataLayer?.shop?.variations_output && 0 !== item.variation_id) {
				contentIds.push(String(wpmDataLayer.products[item.variation_id].dyn_r_ids[wpmDataLayer.pixels.snapchat.dynamic_remarketing.id_type]))
			} else {
				contentIds.push(String(wpmDataLayer.products[item.id].dyn_r_ids[wpmDataLayer.pixels.snapchat.dynamic_remarketing.id_type]))
			}
		})

		return contentIds
	}

	/**
	 * Fire Snapchat event
	 *
	 * @return {boolean}
	 */
	wpm.canFireSnapchatEvent = () => {
		if (!wpmDataLayer?.pixels?.snapchat?.loaded) return false

		if(!wpm.consent.categories.get().marketing) return false

		return true
	}

	/**
	 * Can not fire Snapchat event
	 *
	 * @return {boolean}
	 */
	wpm.canNotFireSnapchatEvent = () => {
		return !wpm.canFireSnapchatEvent()
	}

	/**
	 * Get Snapchat user data
	 * https://docs.snap.com/api/marketing-api/Conversions-API/Parameters#user-data-parameters
	 *
	 * @return {{}}
	 */
	wpm.getSnapchatUserData = () => {
		let data = {}

		if (wpmDataLayer?.user?.email?.sha256) {
			data.em = wpmDataLayer.user.email.sha256
		}

		if (wpmDataLayer?.user?.phone?.sha256?.snapchat) {
			data.ph = wpmDataLayer.user.phone.sha256.snapchat
		}

		// add first name
		if (wpmDataLayer?.user?.first_name?.snapchat) {
			data.fn = wpmDataLayer.user.first_name.snapchat
		}

		// add last name
		if (wpmDataLayer?.user?.last_name?.snapchat) {
			data.ln = wpmDataLayer.user.last_name.snapchat
		}

		// add city
		if (wpmDataLayer?.user?.city?.snapchat) {
			data.ct = wpmDataLayer.user.city.snapchat
		}

		// add state
		if (wpmDataLayer?.user?.state?.snapchat) {
			data.st = wpmDataLayer.user.state.snapchat
		}

		// add postal code
		if (wpmDataLayer?.user?.postal_code?.sha256) {
			data.zp = wpmDataLayer.user.postal_code.sha256
		}

		// add country
		if (wpmDataLayer?.user?.country?.snapchat) {
			data.country = wpmDataLayer.user.country.snapchat
		}

		// external_id
		if (wpmDataLayer?.user?.id?.sha256) {
			data.external_id = wpmDataLayer.user.id.sha256
		}

		// add user_agent from browser
		data.client_user_agent = navigator.userAgent

		// add sc_click_id
		if(snapchatGetScCid()) {
			data.sc_click_id = snapchatGetScCid()
		}

		// add sc_cookie1
		if(wpm.getCookie('_scid')) {
			data.sc_cookie1 = wpm.getCookie('_scid')
		}

		return data
	}

	const snapchatGetScCid = () => {

		// if the ScCid parameter is present in the URL get the value from there,
		// save it in the storage and return it

		// get all URL parameters

		let urlParams = new URLSearchParams(window.location.search)

		if(urlParams.has('ScCid')) {
			let scCid = urlParams.get('ScCid')
			wpm.storeData('snapchat_sc_cid', scCid)
			return scCid
		}

		// if the ScCid parameter is not present in the URL check if it is in the storage
		// and return it
		let scCid = wpm.retrieveData('snapchat_sc_cid')

		if(scCid) {
			return scCid
		}
	}

}(window.wpm = window.wpm || {}, jQuery));
