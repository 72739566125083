/**
 * Copyright 2024 SweetCode. All rights reserved.
 */

(function (wpm, $, undefined) {

	wpm.sendEventPayloadToServerPremium = async payload => {

		const isServerToServerEnabled = () => {

			if (wpmDataLayer?.general?.server_2_server?.active) return true

			return false
		}

		const isServerToServerDisabled = () => {
			return !isServerToServerEnabled()
		}

		const sendPayloadUsingFetch = payload => {

			// Send data to pmw public API with fetch API
			fetch(wpm.root + "pmw/v1/sse/", {
				method     : "POST",
				credentials: "same-origin",
				headers    : {
					"Content-Type": "application/json",
				},
				body       : JSON.stringify(payload),
				keepalive  : true,	// keep connection alive until request is finished
			})
				.then(response => response.json())
				.then(message => {
					message.success ? pmw.console.log(message) : pmw.console.error(message)
				})
				.catch(error => {
					pmw.console.error(error)
				})
		}

		const sendPayloadUsingNavigatorSendBeacon = data => {
			// https://css-tricks.com/send-an-http-request-on-page-exit/
			const success = navigator.sendBeacon(wpm.root + "pmw/v1/sse/", data)

			success ? pmw.console.log("navigator.sendBeacon success") : pmw.console.error("navigator.sendBeacon failed")
		}

		const isWcSessionActive = () => {
			// return true if a WooCommerce session cookie is set
			// The cookie has the name "woocommerce_session_{hash}"
			// The has can vary, so we need to check only for the first part of the cookie name

			const cookies = document.cookie.split(";")

			for (let i = 0; i < cookies.length; i++) {
				const cookie = cookies[i].trim()

				if (cookie.indexOf("woocommerce_cart_hash") === 0) {
					return true
				}
			}

			return false
		}

		const storeIpv6InServerSession = async (ipv6) => {

			pmw.console.log(`Storing IPv6 address in server session (${ipv6})`)

			jQuery.ajax({
				type    : "post",
				dataType: "json",
				url     : wpm.ajax_url,
				data    : {
					action: "pmw_store_ipv6_in_server_session",
					data  : {ipv6},
				},
				success : message => {
					pmw.console.log(message)
					wpm.storeData("store_ipv6_in_server_session", true)
				},
				error   : message => {
					pmw.console.error(message)
					wpm.storeData("store_ipv6_in_server_session", false)
				},
			})
		}

		try {
			// If server to server is disabled, do not send payload
			if (isServerToServerDisabled()) return

			// If IP is not allowed, do not send data
			if (await wpm.isIpNotAllowed()) return

			// If Facebook CAPI is enabled, additionally try to fetch and store the IPv6 address, if available
			if (wpmDataLayer?.pixels?.facebook?.capi) {
				await wpm.getIp("6")


				// If a WooCommerce session is active
				// and if we have not already sent the IPv6 address to the server
				if (
					isWcSessionActive()
					&& !wpm.retrieveData("store_ipv6_in_server_session")
				) {
					if (wpm.retrieveData("ipv6")) {
						await storeIpv6InServerSession(wpm.retrieveData("ipv6"))
					}
				}
			}

			pmw.console.log("Sending event to server", payload)

			if (await wpm.isRestEndpointAvailable()) { // Send data using REST API

				// console.log("Pixel Manager: Sending data using REST API")

				const blob = new Blob([JSON.stringify(payload)], {type: "application/json; charset=UTF-8"})

				// navigator.sendBeacon has a limit of 64kb. Fall back to fetch if the blob is too large.
				if (blob.size < 60000) {
					sendPayloadUsingNavigatorSendBeacon(blob)
				} else {
					sendPayloadUsingFetch(payload)
				}
			} else { // Send data using wp-ajax

				jQuery.ajax(
					{
						type    : "post",
						dataType: "json",
						url     : wpm.ajax_url,
						data    : {
							action: "pmw_server_to_server_event",
							data  : payload,
						},
						success : message => {
							pmw.console.log(message)
						},
						error   : message => {
							pmw.console.error(message)
						},
					})
			}

		} catch (e) {
			console.error(e)
		}
	}

	wpm.acrRemoveCookie = () => {

		// Delete the pmw_checkout_order_created cookie
		// This cookie is used to verify that the purchase confirmation page has been reached

		wpm.deleteCookie("pmw_automatic_conversion_recovery")
	}

	wpm.getAcrOrderDetails = async () => {

		// Get the order details from the pmw_automatic_conversion_recovery cookie
		const acrOrderDetails = JSON.parse(wpm.getCookie("pmw_automatic_conversion_recovery"))

		let response

		// Get order details from the server using the REST API if the REST API is available
		if (await wpm.isRestEndpointAvailable()) {
			response = await fetch(wpm.root + "pmw/v1/acr/order/", {
				method     : "POST",
				credentials: "same-origin",
				cache      : "no-cache",
				headers    : {
					"Content-Type": "application/json",
				},
				body       : JSON.stringify({
					order_id : acrOrderDetails.order_id,
					order_key: acrOrderDetails.order_key,
				}),
			})

		} else {
			// Get order details from the server using the wp-ajax API if the REST API is not available using jQuery
			response = await jQuery.ajax({
				type    : "POST",
				dataType: "json",
				url     : wpm.ajax_url,
				data    : {
					action: "get_acr_order_data_ajax",
					data  : {
						order_id : acrOrderDetails.order_id,
						order_key: acrOrderDetails.order_key,
					},
				},
			})

		}

		const responseJson = await response.json()

		if (responseJson.success) {
			pmw.console.log("ACR order details retrieved successfully")
		} else {
			pmw.console.log("ACR order details could not be retrieved", responseJson)
		}

		wpmDataLayer.products = {...wpmDataLayer.products, ...responseJson.data.products}
		wpmDataLayer.order    = responseJson.data.order
	}

}(window.wpm = window.wpm || {}, jQuery))
